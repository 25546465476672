
<template>
  <div class="login_boxs" @keyup.enter="submitForm('userForm')">
    <!-- 登录表单区域 -->
    <el-form size="mini" :model="userForm" :rules="rules" ref="userForm">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: normal;
        "
      >
        <i @click="$router.back()" class="el-icon-arrow-left back"></i>
        <h2 style="margin-bottom: 20px; margin-top: 20px; text-align: left">
          修改密码
        </h2>
      </div>

      <!-- 用户名 -->
      <el-form-item prop="loginname">
        <el-input
          placeholder="用户名"
          v-model="userForm.loginname"
          prefix-icon="el-icon-user"
        ></el-input>
      </el-form-item>
      <!-- 短信验证码 -->
      <el-form-item class="pr" prop="SmsCode">
        <el-input
          placeholder="短信验证码"
          v-model="userForm.SmsCode"
        ></el-input>
        <button
          @click.prevent="getShortMessageCode()"
          class="code-btn"
          :disabled="!show"
        >
          <span v-show="show">获取验证码</span>
          <span v-show="!show" class="count">{{ count }} s</span>
        </button>
      </el-form-item>
      <!-- <el-form-item prop="SmsCode">
         <el-input 
          placeholder="验证码"
          show-password
          v-model="userForm.SmsCode"
          prefix-icon="el-icon-lock"
        ></el-input>
      </el-form-item> -->
      <!-- 新密码 -->
      <el-form-item prop="newpassword">
        <el-input
          placeholder="新密码"
          show-password
          v-model="userForm.newpassword"
          prefix-icon="el-icon-lock"
        ></el-input>
      </el-form-item>
      <!-- 新密码 -->
      <el-form-item prop="renewpassword">
        <el-input
          placeholder="确认密码"
          show-password
          v-model="userForm.renewpassword"
          prefix-icon="el-icon-lock"
        ></el-input>
      </el-form-item>
      <el-form-item> </el-form-item>
      <!-- 修改密码按钮 -->
      <el-form-item>
        <el-button
          type="primary"
          @click="submitForm('userForm')"
          :loading="loading"
          >修改密码</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { SendSMS, UpdateUserInfoByCode } from "@/network/api";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        let reg = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,10}$/); //6-10位，英文字母+数字，长度为6-10个字符
        let result = reg.test(this.userForm.newpassword);
        if (!result) {
          callback(new Error("英文字母+数字，长度为6-10个字符"));
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.userForm.newpassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      codeimg: "",
      show: true,
      timer: 0,
      count: 0,
      formLabelWidth: "90px",
      userForm: {
        loginname: "",
        SmsCode: "",
        newpassword: "",
        renewpassword: "",
      },
      rules: {
        loginname: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        SmsCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        newpassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        renewpassword: [
          { required: true, message: "请确认密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  mounted() {
    this.userForm.loginname = this.$store.state.login.user;
  },
  methods: {
    // 获取短信验证码
    getShortMessageCode() {
      //axios请求
      // console.log(this.form.phone);
      // console.log(1111111111111);
      // console.log(!this.timer);
      // 验证码倒计时

      let data = {
        SendType: 1,
        LoginName: this.userForm.loginname,
      };
      if (!this.userForm.loginname) {
        return this.$message({ message: "用户名不能为空！", type: "error" });
      }
      SendSMS(data).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: `短信已发送至手机${res.data||''}上,请注意查看！`,
            center: true,
            type: "success",
            offset: "40%",
            duration: 3000,
          });
          // this.form.VcNo = res.data;
          // if (res.msg) {
          //   this.userForm.SmsCode = res.msg;
          // }
          // this.form.VerificationCode = res.msg;
          if (!this.timer) {
            // console.log(this.timer);
            this.count = 60;
            this.show = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= 60) {
                this.count--;
              } else {
                this.show = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        } else {
          this.$message({ message: res.msg, type: "error" });
        }
      });
    },
    submitForm(formName) {
      this.loading = true;
      //   this.dialogFormVisible = false;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //   alert("submit!");
          let data = {
            SmsCode: this.userForm.SmsCode,
            Params: this.userForm.newpassword,
          };
          UpdateUserInfoByCode(data).then((res) => {
            console.log(res);
            if (res.code == -1) {
              this.$message({
                message: "修改失败",
                center: true,
                type: "error",
                offset: "40%",
                duration: 1500,
                onClose: () => {
                  this.$router.replace("/forgetPwd");
                  this.loading = false;
                },
              });
            } else if (res.code == 1) {
              //成功返回登录页
              this.loading = false;
              this.$message({
                message: "修改成功",
                center: true,
                type: "success",
                offset: "40%",
                duration: 1500,
                onClose: () => {
                  this.$router.replace("/Login1");
                  this.loading = false;
                },
              });
            } else if (res.code == 102) {
              //成功返回登录页
              this.$message({
                message: res.msg,
                center: true,
                type: "success",
                offset: "40%",
                duration: 1500,
                onClose: () => {
                  this.$router.replace("/Login1" + window.location.search);
                  this.loading = false;
                },
              });
            } else {
              this.$message({
                message: res.msg,
                offset: "40%",
                center: true,
                type: "error",
                duration: 1500,
                onClose: () => {
                  this.userForm = {
                    loginname: "",
                    oldpassword: "",
                    newpassword: "",
                    renewpassword: "",
                  };
                  this.loading = false;
                },
              });
            }
          });
        } else {
          this.loading = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
.login_boxs {
  width: 100%;
  height: 100%;
  /* width: 290px;
  height: 350px; */
  // background-color: #fff;
  /* background-color: #26447a; */
  /* position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
    background: rgba(255, 255, 255, 0.8);
}
.el-form {
  padding: 20px 30px;
  position: absolute;
  /* bottom: 0; */
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.el-button {
  width: 100%;
  height: 45px;
  font-size: 18px;
  line-height: 30px;
}
.code {
  width: 50%;
  float: left;
}
img {
  /* style="width: 100px; height: 30px; margin-left:5px;vertical-align: middle;" */
  display: line-inline;
  width: 45%;
  height: 28px;
  margin-left: 10px;
  vertical-align: middle;
  border-radius: 3px;
}
.rememberMe {
  color: #fff;
}
/deep/.el-input--mini .el-input__inner {
  height: 40px;
  font-size: 15px;
  line-height: 40px;
}
/* 短信验证码css */
.pr {
  position: relative;
}
.btnss {
  display: flex;
  justify-content: center;
}
.code-btn {
  width: 100px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 222;
  color: #ef8466;
  font-size: 14px;
  border: none;
  border-left: 1px solid #ccc;
  padding-left: 10px;
  background-color: #fff;
  cursor: pointer;
}
.hede {
  box-sizing: border-box;
  width: 100%;
  color: #ff6b1e;
  line-height: 80px;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
}
.back {
  padding: 10px;
  border: 1px solid #ccc;
}
</style>